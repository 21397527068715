















































































import Vue, { VueConstructor } from 'vue';
import { mapGetters } from 'vuex';
import { ApiResource } from '@/types';
import ExternalAccountForm from '@/components/dashboard/ExternalAccountForm.vue';

interface VuexBindings {
  detect: (idOrVanity: string) => ApiResource.Business,
  findAccount: (id: string) => ApiResource.Business.Account | undefined,
}

export default (Vue as VueConstructor<Vue & VuexBindings>).extend({
  components: {
    ExternalAccountForm,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      detect: 'business/Detect',
      findAccount: 'business/FindAccount',
    }),
    COMMISSION_ID(): string {
      return this.$route.params.COMMISSION_ID;
    },
    VANITY(): string {
      return this.$route.params.VANITY;
    },
    BUSINESS(): ApiResource.Business {
      return this.detect(this.VANITY);
    },
    PAYOUT_ACCOUNT(): ApiResource.Business.Account | undefined {
      return this.findAccount(this.BUSINESS.id);
    },
  },
  mounted() {
    if (this.$route.query.onboarding_result === 'failure') {
      this.redirectToHostedOnboarding();
    } else if (this.$route.query.onboarding_result === 'success') {
      this.$router.push({
        name: 'dashboard.welcome.step-3',
      });
    }
  },
  methods: {
    async redirectToHostedOnboarding() {
      this.loading = true;

      const data = await this.$store.dispatch('business/GetAccountLink', {
        BUSINESS_ID: this.BUSINESS.id,
        form: {
          type: 'verify',
          collect: 'current',
          route: 'welcome',
        },
      });

      window.location.href = data.url;
    },
  },
});




















































































































import Vue, { VueConstructor } from 'vue';
import { mapGetters } from 'vuex';
import { ApiResource } from '@/types';
import ShareComponent from '@/components/Share.vue';
import SlotsForm from '@/components/dashboard/SlotsForm.vue';

interface VuexBindings {
  detect: (idOrVanity: string) => ApiResource.Business,
}

export default (Vue as VueConstructor<Vue & VuexBindings>).extend({
  components: {
    ShareComponent,
    SlotsForm,
  },
  computed: {
    ...mapGetters({
      detect: 'business/Detect',
    }),
    COMMISSION_ID(): string {
      return this.$route.params.COMMISSION_ID;
    },
    VANITY(): string {
      return this.$route.params.VANITY;
    },
    BUSINESS(): ApiResource.Business {
      return this.detect(this.VANITY);
    },
    profileUrl(): string {
      return `${process.env.VUE_APP_BASE_URL}/maker/${this.BUSINESS.vanity}`;
    },
    profilePermalink(): string {
      return `${process.env.VUE_APP_BASE_URL}/maker?id=${this.BUSINESS.id}`;
    },
    profileShortUrl(): string {
      return `https://auftr.ag/${this.BUSINESS.vanity}`;
    },
  },
});

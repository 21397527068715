






import Vue from 'vue';

export default Vue.extend({
  props: {
    shareDescription: {
      type: String,
      required: false,
      default: '',
    },
    shareTags: {
      type: Array as () => string[],
      required: false,
      default() {
        return [];
      },
    },
    shareTitle: {
      type: String,
      required: false,
      default: '',
    },
    shareTwitterRelated: {
      type: Array as () => string[],
      required: false,
      default() {
        return [];
      },
    },
    shareTwitterVia: {
      type: String,
      required: false,
      default: '',
    },
    shareUrl: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    urlDiaspora(): string {
      const base = 'https://share.diasporafoundation.org/';
      const query = new URLSearchParams();

      query.append('url', this.shareUrl);
      query.append('title', this.shareTitle);

      return `${base}?${query}`;
    },
    queryMastodon(): string {
      const query = new URLSearchParams();

      query.append('url', this.shareUrl);
      query.append('text', this.shareTitle);

      return query.toString();
    },
    urlMastodonSocial(): string {
      return `https://mastodon.social/share?${this.queryMastodon}`;
    },
    urlMastodonAufDeineArt(): string {
      return `https://aufdeine.art/share?${this.queryMastodon}`;
    },
    urlMastodonArtAlley(): string {
      return `https://artalley.social/share?${this.queryMastodon}`;
    },
    urlReddit() {
      const base = 'https://reddit.com/submit';
      const query = new URLSearchParams();

      query.append('url', this.shareUrl);
      query.append('title', this.shareTitle);

      return `${base}?${query}`;
    },
    urlPinterestButton() {
      const base = 'https://pinterest.com/pin/create/button/';
      // const base = 'https://pinterest.com/pin/create/link/';
      const query = new URLSearchParams();

      query.append('url', this.shareUrl);

      return `${base}?${query}`;
    },
    urlPinterestLink() {
      const base = 'https://pinterest.com/pin/create/link/';
      const query = new URLSearchParams();

      query.append('url', this.shareUrl);

      return `${base}?${query}`;
    },
    urlTumblr() {
      const base = 'https://www.tumblr.com/widgets/share/tool';
      const query = new URLSearchParams();

      query.append('canonicalUrl', this.shareUrl);
      query.append('title', this.shareTitle);
      query.append('caption', this.shareDescription);
      query.append('tags', this.shareTags.join(','));

      return `${base}?${query}`;
    },
    urlTwitter() {
      const base = 'https://twitter.com/intent/tweet';
      const query = new URLSearchParams();

      query.append('url', this.shareUrl);
      query.append('text', this.shareTitle);
      query.append('related', this.shareTwitterRelated.join(','));
      if (this.shareTwitterVia) query.append('via', this.shareTwitterVia);
      query.append('hashtags', this.shareTags.join(','));

      return `${base}?${query}`;
    },
    urlXing() {
      const base = 'https://www.xing.com/spi/shares/new';
      const query = new URLSearchParams();

      query.append('url', this.shareUrl);

      return `${base}?${query}`;
    },
  },
});
